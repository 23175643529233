export enum TrackingEvents {
    PROFILE_EDITION_OPENED = 'profile_edition_opened',
    // AS-2160: this event is temporary, to check this internal feature usage
    TEMPORARY_PROFILE_TO_PDF = 'temp_profile_to_pdf',
    RESUME_IMPORT_FUNNEL = 'profile_import_funnel_step_viewed',
    TRANSLATION = 'freelancer_profile_translated',
}

export type TemporaryProfileToPdfEventPayload = {
    profile_to_pdf_anynomization: boolean;
    profile_to_pdf_dailyrate_edition: boolean;
    profile_to_pdf_dailyrate_display: boolean;
    profile_to_pdf_skillset_display: boolean;
    profile_to_pdf_industryexpertise_display: boolean;
    profile_to_pdf_title_display: boolean;
};

export enum EditionOpenedEntryPoint {
    PROFILE_PAGE = 'profile_page',
    PROFILE_GAUGE = 'profile_gauge',
    PROFILE_TASK_CARD = 'profile_task_card',
    PROFILE_POPUP = 'profile_popup',
    MESSENGER = 'messenger',
}

export enum EditionOpenedSection {
    SKILL_SET = 'skill_set',
    LANGUAGES = 'languages',
    DESCRIPTION = 'description',
    LOCATION = 'location',
    INDUSTRY_EXPERTISE = 'industry_expertises',
    CERTIFICATION = 'certification',
    EDUCATIONS = 'educations',
    EXPERIENCES = 'experiences',
    EXPERIENCE_LEVEL = 'experience_level',
    HEADLINE = 'headline',
    CATEGORIES = 'categories',
    INFLUENCE = 'influence',
    DAILY_RATE = 'daily_rate',
    PROJECT_DURATION_PREFERENCES = 'project_duration_preferences',
    PROJECT_COMPANY_SIZE_PREFERENCES = 'project_company_size_preferences',
    PROJECT_ACTIVITY_AREA_PREFERENCES = 'project_activity_area_preferences',
}

export enum EditionOpenedWorkflow {
    MANUAL = 'manual',
    UPLOAD = 'upload',
}

export enum ResumeImportStep {
    UPLOAD_RESUME = 'upload',
    DUPLICATED_EXPERIENCES = 'duplicate',
    EXPERIENCE_LIST = 'all_experiences',
    IMPORT_DONE = 'profile_page_import_done',
}

export enum TranslationAction {
    TRANSLATE = 'translate',
    REVERSE = 'reverse',
}
